<template>
  <div class="bg">
    <div class="top mb8">
      <img src="../../../assets/img/ambulance.png" alt="" />
    </div>
    <div class="bot">
      <h1>1、拨通120后如何与调度员沟通？</h1>
      <div>
        址应尽量详细，尽可能地叙述到X区X路X小区X号楼X单元X楼X户。目前城市居民小区多是高楼大厦，并且非常密集，如果叙述不够详细，会延长急救人员到达病人身边的时间，耽误最佳治疗时机。
      </div>
      <h1>2、告知调度员，病人的典型的发病表现</h1>
      <div>
        如果是外伤，我们需这样说：“什么时候+什么原因+哪个部位+出现什么情况。”如果是非外伤，我们需要这样说：“哪个部位+怎么不舒服+多久了。”
      </div>
      <h1>3、不要在调度员之前挂断电话一定要等120调度人员先挂电话。</h1>
      <div>
        有些人一心急，报完地址就匆匆挂了电话，这是非常错误的，务必等120调度人员询问完相关信息，挂断电话后您再挂机
      </div>
      <h1>4、提前做好搬运准备</h1>
      <div>
        如果病人需要搬运，但处在电梯停运的楼房，等待期间应先与物业沟通好，让他们把电梯打开;若是走楼梯，则应
      </div>
    </div>
       <div class="btnbox">
        <van-button round type="info" size="large" @click="gonav('privatedoctorordeinfo')" color='rgba(29,174,252,1)'
          >联系客服</van-button
        >
      </div>
  </div>
</template>
<script>
export default {
  data() {
    return {
      
    }
  },
  methods: {
    gonav(){}
  },
};
</script>
<style lang="less" scoped>
.bg {
  .top {
    background: #fff;

    height: 200px;
    img {
      width: 100%;
      height: 100%;
    }
  }
  .bot {
    background: #fff;

    padding: 16px;
    h1 {
      color: rgba(29, 174, 252, 1);
      font-size: 16px;
      font-weight: 700;
      margin-bottom: 20px;
    }
    div {
      margin-bottom: 20px;
    }
  }
   .btnbox{
    padding: 40px;
    background: #fff;
  }
}
</style>
